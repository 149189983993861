import { createWebHistory, createRouter } from "vue-router";

const ViewLogin = () => import("@/views/ViewLogin.vue")
const ViewHome = () => import("@/views/ViewHome.vue")
const ViewSettings = () => import("@/views/ViewSettings.vue")

const routes = [
    {
        path: "/",
        name: "ViewHome",
        component: ViewHome,
    },
    {
        path: "/login",
        name: "ViewLogin",
        component: ViewLogin
    },
    {
        path: "/settings",
        name: "ViewSettings",
        component: ViewSettings,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router;